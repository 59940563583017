<template>
  <bumps-template
    :comedones="comedones"
    :gender="gender"
    :comedo-locations="comedoLocations"
    :comedo-locations-visible="comedoLocationsVisible"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import BumpsTemplate from '@/modules/questionnaire/components/steps/photo-analysis/skin-texture/bumps/BumpsTemplate';

import { makeStep, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { no, yes } from '@/modules/questionnaire/api/helpers';

const FIELDS_TO_RESET = ['comedoLocations', 'otherComedoLocations', 'textureDiscomfort'];

const { field, requiredField, requiredArrayField } = fieldBuilder;

const FIELDS = [
  field('gender'),
  field('textureDiscomfort'),
  field('otherComedoLocations'),
  requiredField('comedones'),
  requiredArrayField('comedoLocations')
];

export default {
  name: 'Bumps',
  components: {
    BumpsTemplate
  },
  mixins: [makeStep(FIELDS), resetStoreValues],
  computed: {
    comedoLocationsVisible() {
      return yes(this.comedones);
    }
  },
  watch: {
    comedones(newValue) {
      if (no(newValue)) {
        this.resetStoreFieldsByName(FIELDS_TO_RESET);
      }

      if (!this.comedoLocationsVisible) {
        this.showNextStep();
        return;
      }

      this.scrollTo('#comedo-locations');
    }
  },
  methods: {
    isFieldVisible(fieldName) {
      if (fieldName === 'comedoLocations') {
        return this.comedoLocationsVisible;
      }

      return true;
    }
  }
};
</script>
